import ACTION_TYPES from '../actionTypes.json';
import apiService from '../../services/apiService';

export const loadProjectData = () => async(dispatch) => {
  const projectData = await apiService.getProjectData();
  
  dispatch({
    type: ACTION_TYPES.LOAD_PROJECT_DATA,
    payload: projectData,
    // isLoaded: true
  });
}
