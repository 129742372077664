import React, {useEffect, Suspense} from 'react';
import Box from '@mui/material/Box';
import { loadProjectData } from '../redux/actions/projectActions';
import { connect } from 'react-redux';
import assetBuilder from '../services/assetBuilder';

const MainPage = (props) => {

  // useEffect(() => {
    
  // }, []);

    return (
      <div className="main">
          <Box className="logo" sx={{ width: 256, height: 256 }} />
      </div>
    )
  }

  const mapStateToProps = (state) => { 
    // console.log('2134124124');
    // console.log(state.project.isLoaded);
    // const path = assetBuilder(state.project.background_image);
    // console.log(path);
    // if (state.project.isLoaded) {
      document.documentElement.style.setProperty('--asset-background_image', `url(${assetBuilder(state.project.background_image)})`);
    // }
    return { 
      project: state.project,
    }
  }

  export default connect(mapStateToProps, { loadProjectData })(MainPage);
  