import React, { useEffect, useState, useCallback } from 'react';
import Container from '@mui/material/Container';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Carousel, { Modal, ModalGateway } from "react-images";
import IconButton from '@mui/material/IconButton';

import { LoadSuitesData } from '../redux/actions/suitesActions';
import { connect } from 'react-redux';
import { withRouter } from "../redux/actions/withRouter";

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const Suites = (props) => {

    const [currentImageIndex, setCurrentImageIndex] = useState(1);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    console.log('Suites!');
    console.log(props);

    const openLightbox = useCallback((event, { photo, index }) => {
        console.log(event);
        console.log(photo);
        setCurrentImageIndex(index);
        setViewerIsOpen(true);
        console.log("open: "+viewerIsOpen);
        console.log("currentImageIndex: "+currentImageIndex);
      }, [])
    
      const openLightbox2 = (index) => {
        // console.log(event);
        // console.log(photo);
        setCurrentImageIndex(index);
        setViewerIsOpen(true);
        console.log("open: "+viewerIsOpen);
        console.log("currentImageIndex: "+currentImageIndex);
      }
    
      const closeLightbox = () => {
        setCurrentImageIndex(0);
        setViewerIsOpen(false);
        console.log("close: "+viewerIsOpen);
      }
    
      const gotoPrevious = () => {
        currentImageIndex > 0 && setCurrentImageIndex(currentImageIndex - 1);
      }
    
      const gotoNext = () => {
        currentImageIndex + 1 < props.overview.images.length &&
        setCurrentImageIndex(currentImageIndex + 1);
      }

    useEffect(() => {
        // console.log("Hello");
        props.LoadSuitesData();
        document.title = props.title + ' - Suites';
        document.documentElement.style.setProperty('--asset-background_image', 'none')
        console.log(props);
    }, []);

    return (
      <div className="suites">
        {console.log('---------------')}
        {console.log(props)}
        <ImageList variant="masonry" cols={3} gap={8}>
          {props.suites.images.map((item, index) => (
              // <div key={index}>
              // {({ ref, open }) => (
              <ImageListItem key={index} onClick={(e)=> {openLightbox2(index)}}>
                  <img
                  // ref={ref} 
                  // onClick={open} 
                  src={`${item.src}?w=248&fit=crop&auto=format`}
                  // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  // loading="lazy"
                  />
                  <ImageListItemBar
                    title={item.title}
                //     actionIcon={
                //       <IconButton
                //         sx={{ color: 'rgba(255, 255, 255, 1)' }}
                //         // aria-label={`info about ${item.title}`}
                  //   >
                //       <BookmarkBorderIcon />
                //     </IconButton>
                  // }
                  />
              </ImageListItem>
              // ))}
          ))}
        </ImageList>

        <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImageIndex}
              views={props.suites.images.map(x => ({
                ...x,
                width: x.width,
                height: x.height,
                src: x.src,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      </div>
    );
}

const mapStateToProps = (state) => {
    console.log(state);
    return { suites: state.suites }
}

// export default connect(mapStateToProps, { LoadAmenitiesData })(Amenities);
export default withRouter(connect(mapStateToProps, { LoadSuitesData })(Suites));