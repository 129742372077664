import React from 'react';

const onClickDefault = () => {console.log('Event Not Attached')}

const A = React.forwardRef((props, ref) => {
  const onClickEvent = props.onClick ?? onClickDefault
  if (!props.disabled)
    return (<div className={props.className} style={{cursor:'pointer'}} onClick={onClickEvent} ref={ref}>{props.children}</div>)
  else 
    return (<div className={`${props.className} non-selectable`} ref={ref}>{props.children}</div>)
});

export default A;