import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAuthenticated: false,
    authFailed: false,
    inProgress: false
}

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        isAuthenticated: (state) => {
            state.isAuthenticated = true;
        },
        authFailed: (state) => {
            state.authFailed = true;
        },
        inProgress: (state) => {
            state.inProgress = true;
        }
    }
})

export const { isAuthenticated, authFailed, inProgress } = authenticationSlice.actions;
export default authenticationSlice.reducer;


// const authenticateReducer = (authenticated = defaultObject, action) => {
//     if (action.type === ACTION_TYPES.AUTHENTICATION.AUTH_SUCCESS)
//         return { ...action.payload };
//     if (action.type === ACTION_TYPES.AUTHENTICATION.LOG_OUT)
//         return { ...defaultObject };
//     if (action.type === ACTION_TYPES.AUTHENTICATION.AUTH_FAILED)
//         return { ...defaultObject, authFailed: true }
//     if (action.type === ACTION_TYPES.AUTHENTICATION.IN_PROGRESS)
//         return { ...defaultObject, inProgress: true }
//     if (action.type === ACTION_TYPES.AUTHENTICATION.AUTH_REFRESH)
//         return { ...action.payload, isAuthenticated: true, email: authenticated.email }

//     return authenticated;
// }

// export default authenticateReducer;