import { configureStore } from '@reduxjs/toolkit'

import projectReducer from './reducers/projectReducer';
import authenticateReducer from '../features/authentication/authenticationSlice';
import overviewReducer from './reducers/overviewReducer';
import amenitiesReducer from './reducers/amenitiesReducer';
import storyReducer from './reducers/storyReducer';
import suitesReducer from './reducers/suitesReducer';
import floorplanReducer from './reducers/floorplanReducer';
import neighbourhoodReducer from './reducers/neighbourhoodReducer';

export const store = configureStore({
  reducer: {
    authentication: authenticateReducer,
    project: projectReducer,
    overview: overviewReducer,
    amenities: amenitiesReducer,
    story: storyReducer,
    suites: suitesReducer,
    floorplans: floorplanReducer,
    neighbourhood: neighbourhoodReducer
  }
})

export default store;

