import React from 'react';
import { connect } from 'react-redux';
import { StoryFetchData } from '../redux/actions/storyActions';
import assetBuilder from '../services/assetBuilder';


import fb from '../assets/img/icons/fb.svg';
import tw from '../assets/img/icons/tw.svg';
import ig from '../assets/img/icons/ig.svg';
import A from './generic/A';

class BuilderStoryTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false }
  }

  componentDidMount() {
    this.props.StoryFetchData();
    setTimeout(() => this.setState({ loaded: true }), 250)
  }

  renderBody = () => {
    const paragraphs = this.props.story.description.split('\n');
    return paragraphs.map((p, i) => <p key={i}>{p}</p>);
  }

  onStoryLogoClick = () => {
    window.open(this.props.story.url, '_blank');
  }

  render() {
    const { story } = this.props;

    return (
      <div className={`builder-story-template ${this.state.loaded ? 'builder-story-template-loaded' : ''}`}>
        <div className="bs-content">
          <div className="bs-logo" onClick={this.onStoryLogoClick} style={{ cursor: 'pointer' }}>
            <img src={assetBuilder(story.logo)} alt="logo" />
          </div>
          <div className="bs-header">
            {story.title}
          </div>
          <div className="bs-text">
            {this.renderBody()}
          </div>
          <div className="bs-social-media">
            {story.sm?.fb ? <A onClick={() => window.open(story?.sm?.fb, '_blank')}><img src={fb} height={24} width={24} alt="fb" /></A> : null}
            {story.sm?.tw ? <A onClick={() => window.open(story?.sm?.tw, '_blank')}><img src={tw} height={24} width={24} alt="tw" /></A> : null}
            {story.sm?.ig ? <A onClick={() => window.open(story?.sm?.ig, '_blank')}><img src={ig} height={24} width={24} alt="ig" /></A> : null}
          </div>
        </div>
        <div className="bs-image">
          <img src={assetBuilder(story.asset)} alt="flair" />
        </div>
      </div>
    )
  }
} //window.open(story.sm.ig, '_blank')

const mapStateToProps = state => { return { story: state.story } }
export default connect(mapStateToProps, { StoryFetchData })(BuilderStoryTemplate);