import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Gallery from './Gallery';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import DomainIcon from '@mui/icons-material/Domain';
import StarIcon from '@mui/icons-material/Star';
import WeekendIcon from '@mui/icons-material/Weekend';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MapIcon from '@mui/icons-material/Map';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { NavLink } from 'react-router-dom';

import assetBuilder from '../services/assetBuilder';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)})`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)})`,
    },
  });
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  
  // const AppBar = styled(MuiAppBar, {
  //   shouldForwardProp: (prop) => prop !== 'open',
  // })(({ theme, open }) => ({
  //   zIndex: theme.zIndex.drawer + 1,
  //   transition: theme.transitions.create(['width', 'margin'], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen,
  //   }),
  //   ...(open && {
  //     marginLeft: drawerWidth,
  //     width: `calc(100% - ${drawerWidth}px)`,
  //     transition: theme.transitions.create(['width', 'margin'], {
  //       easing: theme.transitions.easing.sharp,
  //       duration: theme.transitions.duration.enteringScreen,
  //     }),
  //   }),
  // }));
  
  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

  const container = document.getElementsByClassName('Container');
  
  export default function Navigation(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [openNeighbourhood, setOpenNeighbourhood] = React.useState(false);
    const [openSuites, setOpenSuites] = React.useState(false);
    const [openFloorplans, setOpenFloorplans] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState();

    // const refreshPage = () =>{
    //   window.location.reload();
    // }

    let navigate = useNavigate();

    // const removeBackground = () => {
    //   document.documentElement.style.setProperty('--asset-background_image', props.project.background_image);
    // }
  
    // const addBackground = () => {
    //   document.documentElement.style.setProperty('--asset-background_image', 'none');
    // }

    const handleToggle = () => {
      if (!open){
        container[0].style.marginLeft = `${drawerWidth}px`;
        
      }else{
        container[0].style.marginLeft = '64px';
      }
        setOpen(!open);

    }

    const setPagetitle = ({title}) => {
      setCurrentPage({title});
    }

    const handleNeighbourhoodToggle = () => {
        setOpenNeighbourhood(!openNeighbourhood);
    }

    const handleSuitesToggle = () => {
        setOpenSuites(!openSuites);
    }

    const handleFloorplansToggle = () => {
        setOpenFloorplans(!openFloorplans);
    }
  
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleToggle}
            edge="start"
            sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
            }}
        >
            <MenuIcon />
        </IconButton> */}

        {/* <AppBar position="fixed" open={open}> */}
          {/* <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleToggle}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{mx:6}}>
                {currentPage}
            </Typography>
          </Toolbar> */}
        {/* </AppBar> */}
        
        <Drawer className="navDrawer" variant="permanent" open={open}>

            {open ? (
                <div className="logoNav" style={{marginTop: 64, height: 128, width: 128}} onClick={(e)=>navigate('/')}>
                  {/* <img src={`${assetBuilder(props.floorplans.project.logo)}`} width={128} height={128} alt='logo' onClick={(e)=>navigate('/')}/> */}
                </div>
            ) : (
                <div className="logoNav" style={{marginTop: 64, height: 128, width: 128}} onClick={(e)=>navigate('/')}>
                  {/* <img src={`${assetBuilder(props.floorplans.project.logo)}`} width={64} height={64} alt='logo' onClick={(e)=>navigate('/')}/> */}
                </div>
            )}
            

          <DrawerHeader className="DrawerHeader">
            <IconButton onClick={handleToggle} sx={{ width: 48, height: 48 }}>
              {open ? <ChevronLeftIcon/> : <MenuIcon />}
            </IconButton>
            {/* {console.log(props.floorplans.project.logo)} */}
            {/* background-image: var(--asset-background_image); */}
          </DrawerHeader>
          {/* <Divider /> */}
          <List sx={{p:1}} >
            {/* {['Overview', 'Neighbourhood', 'Amenities', 'Suites', 'Floorplans', 'Build Story'].map((text, index) => (
              <ListItemButton
                key={text}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            ))} */}

            <ListItem dense={false} sx={{p:1}}>
              <ListItemIcon style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-center',
                padding: theme.spacing(0, 1),
              }}>
                <DomainIcon/>
              </ListItemIcon>
              
              <NavLink className="active"
                to="/overview"
                // onClick={() => removeBackground()}
                style={({ isActive }) => ({
                  color: isActive ? '#2F80ED' : '#000000',
                  textDecoration: 'none'
                })}
              >
                <ListItemText>OVERVIEW</ListItemText>
              </NavLink>

            </ListItem>

            {/* <MenuItem 
              title="Neighbourhood" 
              icon={<MapIcon />}
              onClick={()=> <Neighbourhood />}
              // onClick={()=> console.log('test2')}
            /> */}

            <ListItem dense={false} sx={{p:1}}>
              <ListItemIcon style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-center',
                padding: theme.spacing(0, 1),
              }}>
                <MapIcon/>
              </ListItemIcon>
              
              <NavLink className="active"
                to="neighbourhood"
                // onClick={() => removeBackground()}
                style={({ isActive }) => ({
                  color: isActive ? '#2F80ED' : '#000000',
                  textDecoration: 'none'
                })}
              >
                <ListItemText>NEIGHBOURHOOD</ListItemText>
              </NavLink>

            </ListItem>

            {/* <Popover 
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              The content of the Popover.
            </Popover>
            <ListItem disablePadding>
                <ListItemButton onClick={handleNeighbourhoodToggle}>
                <ListItemIcon>
                    
                </ListItemIcon> */}
                {/* <ListItemText primary="Neighbourhood" /> */}
                
                {/* {openNeighbourhood ? <ExpandLess /> : <ExpandMore />} */}
                {/* </ListItemButton>
            </ListItem> */}

            {/* <Collapse in={openNeighbourhood} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                        <ChevronRightIcon />
                    </ListItemIcon>
                    <ListItemText primary="Starred" />
                </ListItemButton>
                </List>
            </Collapse> */}

            {/* <ListItem disablePadding>
                <ListItemButton>
                <ListItemIcon>
                    <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Amenities" />
                </ListItemButton>
            </ListItem> */}

            <ListItem dense={false} sx={{p:1}}>
              <ListItemIcon style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-center',
                padding: theme.spacing(0, 1),
              }}>
                <StarIcon/>
              </ListItemIcon>
              
              <NavLink className="active"
                to="amenities"
                // onClick={() => removeBackground}
                style={({ isActive }) => ({
                  color: isActive ? '#2F80ED' : '#000000',
                  textDecoration: 'none'
                })}
              >
                <ListItemText>AMENITIES</ListItemText>
              </NavLink>

            </ListItem>

            <ListItem dense={false} sx={{p:1}}>
              <ListItemIcon style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-center',
                padding: theme.spacing(0, 1),
              }}>
                <WeekendIcon/>
              </ListItemIcon>
              
              <NavLink className="active"
                to="suites"
                // onClick={() => removeBackground}
                style={({ isActive }) => ({
                  color: isActive ? '#2F80ED' : '#000000',
                  textDecoration: 'none'
                })}
              >
                <ListItemText>SUITES</ListItemText>
              </NavLink>

            </ListItem>

            <ListItemButton dense={false} sx={{p:1}} onClick={handleFloorplansToggle}>
                {/* <ListItemButton dense onClick={handleFloorplansToggle}> */}
                <ListItemIcon style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-center',
                  padding: theme.spacing(0, 1),
                }}>
                    <DashboardIcon />
                </ListItemIcon>

                <ListItemText primary="FLOORPLANS"
                sx={({ isActive }) => ({
                    color: isActive ? '#2F80ED' : '#000000',
                    textDecoration: 'none'
                  })}
                />
                {openFloorplans ? <ExpandLess /> : <ExpandMore />}
                {/* </ListItemButton> */}
            </ListItemButton>

            {/* {console.log(props.floorplans.project.isLoaded)} */}
            {props.floorplans.project.isLoaded ? (
              <>
              {/* {props.floorplans.project.floorplans.length < 0 ? (
                <> */}

                <Collapse in={openFloorplans} timeout="auto" unmountOnExit>

                <List component="div" disablePadding>
                  {console.log('props:')}
                  {console.log(props)}
                    {props.floorplans.project.floorplans.map((item, index) => (

                      <ListItem dense={false} sx={{p:1}} key={index}>
                        <ListItemButton>
                          <ListItemIcon style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-center',
                            padding: theme.spacing(0, 1),
                          }}>
                            <ChevronRightIcon />
                          </ListItemIcon>
                        </ListItemButton>
                        <NavLink 
                          className="active"
                          to={`floorplans/${item}`}
                          // onClick={() => removeBackground}
                          // onClick={(e)=>navigate(`floorplans/${item}`)}
                          // to={{ pathname: `floorplans/${item}/reload` }}
                          // onClick={refreshPage}
                          style={({ isActive }) => ({
                            color: isActive ? '#2F80ED' : '#000000',
                            textDecoration: 'none'
                          })}
                        >
                            <ListItemText>
                              <Typography variant="body2" display="block" gutterBottom>
                                {item}
                              </Typography>
                            </ListItemText>
                        </NavLink>

                      </ListItem>

                    ))}

                    </List>
                </Collapse>
                </>
              // ) : <div> Loading... </div> }
              // </>
            ) : null }

            <ListItem dense={false} sx={{p:1}}>
              <ListItemIcon style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-center',
                padding: theme.spacing(0, 1),
              }} >
                <MenuBookIcon/>
              </ListItemIcon>
              
              <NavLink className="active"
                to="story"
                // onClick={() => removeBackground}
                style={({ isActive }) => ({
                  color: isActive ? '#2F80ED' : '#000000',
                  textDecoration: 'none'
                })}
              >
                <ListItemText>BUILDER STORY</ListItemText>
              </NavLink>
            </ListItem>
          </List>
        </Drawer>
        </Box>
    );
  }