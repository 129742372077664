import ACTION_TYPES from '../actionTypes.json';
import apiService from '../../services/apiService';
import assetBuilder from '../../services/assetBuilder';

export const StoryFetchData = () => async(dispatch) => {
  const storyData = await apiService.getStoryData();

  if (storyData.asset != null){
    storyData.asset = assetBuilder(storyData.asset);
    console.log('storyData');
    console.log(storyData);
  }
  
  dispatch({
    type: ACTION_TYPES.BUILDER_STORY,
    payload: storyData
  });
}
