import React, { useEffect, useState, useCallback } from 'react';
// import Container from '@mui/material/Container';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Carousel, { Modal, ModalGateway } from "react-images";
import IconButton from '@mui/material/IconButton';

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

import { NeighbourhoodFetch } from '../redux/actions/neighbourhoodActions';
import { connect } from 'react-redux';
import { withRouter } from "../redux/actions/withRouter";

const Neighbourhood = (props) => {

  const [currentImageIndex, setCurrentImageIndex] = useState(1);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
      setCurrentImageIndex(index);
      setViewerIsOpen(true);
    }
  
    const closeLightbox = () => {
      setCurrentImageIndex(0);
      setViewerIsOpen(false);
    }
    
    useEffect(() => {
        props.NeighbourhoodFetch();
        document.documentElement.style.setProperty(`--asset-${'background_image'}`, 'none');
        document.title = props.title + ' - Neighbourhood';
    }, []);

    return (
    <div className="neighbourhood">
          <ImageList variant="masonry" cols={3} gap={8}>
          {props.neighbourhood.images.map((item, index) => (
              // <div key={index}>
              // {({ ref, open }) => (
              <ImageListItem key={index} onClick={(e)=> {openLightbox(index)}}>
                  <img
                  // ref={ref} 
                  // onClick={open} 
                  src={item.src}
                  alt={item.title}
                  // loading="lazy"
                  />
                  <ImageListItemBar
                      title={item.title}
                    //   actionIcon={
                    //     <IconButton
                    //       sx={{ color: 'rgba(255, 255, 255, 1)' }}
                    //     >
                    //       <BookmarkBorderIcon />
                    //     </IconButton>
                    // }
                  />
              </ImageListItem>
              // ))}
          ))}
          </ImageList>

          <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImageIndex}
                views={props.neighbourhood.images.map(x => ({
                  ...x,
                  width: x.width,
                  height: x.height,
                  src: x.src,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    );
}

const mapStateToProps = (state) => {
    return { 
      neighbourhood: state.neighbourhood 
    }
  }

  export default withRouter(connect(mapStateToProps, { NeighbourhoodFetch })(Neighbourhood));