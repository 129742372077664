import ACTION_TYPES from '../actionTypes.json';
import assetBuilder from '../../services/assetBuilder';

const defaultObject = {
  isLoaded: false
}

const setDocumentStyleVariable = (name, payload) => document.documentElement.style.setProperty(`--color-${name}`, payload[name]);
const setDocumentAssetVariable = (name, payload) => { 
  document.documentElement.style.setProperty(`--asset-${name}`, `url(${assetBuilder(payload[name])})`)
  // console.log(`--asset-${name}`, `url(${assetBuilder(payload[name])})`);
  // console.log('payload: ');
  // console.log(payload[name]);
}

const projectReducer = (current=defaultObject, {type, payload}) => {
  if (type === ACTION_TYPES.LOAD_PROJECT_DATA) {
    // Set document colors
    // console.log(payload);
    // setDocumentStyleVariable('action', payload);
    // setDocumentStyleVariable('background_colour', payload);
    // setDocumentStyleVariable('on_action', payload);
    // setDocumentStyleVariable('on_action_active', payload);
    // setDocumentStyleVariable('on_primary', payload);
    // setDocumentStyleVariable('on_primary_active', payload);
    // setDocumentStyleVariable('on_secondary', payload);
    // setDocumentStyleVariable('on_secondary_active', payload);
    // setDocumentStyleVariable('on_surface', payload);
    // setDocumentStyleVariable('primary', payload);
    // setDocumentStyleVariable('secondary', payload);
    // setDocumentStyleVariable('surface', payload);
    // Set document images
    // setDocumentAssetVariable('background_image', payload);
    setDocumentAssetVariable('logo', payload);
    return {...payload, isLoaded: true}
  }
  return current;
}

export default projectReducer;