import ACTION_TYPES from '../actionTypes.json';
import apiService from '../../services/apiService';
import assetBuilder from '../../services/assetBuilder';

export const loadOverviewData = () => async(dispatch) => {
  const overviewData = await apiService.getOverviewData();
  const images = overviewData.map(o => {
    // const min = 400;
    // const max = 1400;
    // const rand = min + Math.random() * (max - min);
    // const rand2 = min + Math.random() * (max - min);
    const gcd = (a, b) => {
      return (b == 0) ? a : gcd (b, a%b);
    }
    // const ratio = o.width / o.height);
    // const w = o.width;
    // const h = o.height;
    // const r = gcd (w, h);
    // const aspectratio = o.width/o.height;

    return {
      id: o.id,
      alt: o.title,
      src: assetBuilder(o.asset),
      // isVideo: o.asset ? o.asset.endsWith('mp4') : false, 
      title: o.title,
      key: `${o.id}`,
      // width: parseFloat(o.width),
      // height: parseFloat(o.height)
      width: o.width / 3,
      height: o.height / 3,
      // width: aspectratio[0],
      // height: aspectratio[1],
      // aspect: r,
      // aspectRatio: aspectratio,
      // width: 1,
      // height: 1,
      // width: getWidth,
      // height: rand2,
      // loading: 'lazy'
    }
  });

  dispatch({
    type: ACTION_TYPES.OVERVIEW_ACTIONS.FETCH_DATA,
    payload: {
      images,
      activeImage: 0,
      expandedView: false,
      // isLoaded: true
    }
  });
}

export const OverviewSetActiveImage = (id) => {
  return {
    type: ACTION_TYPES.OVERVIEW_ACTIONS.SET_ACTIVE_IMAGE,
    payload: id
  }
}

export const OverviewToggleExpandedView = (toggle) => {
  return {
    type: ACTION_TYPES.OVERVIEW_ACTIONS.TOGGLE_EXPANDED_VIEW,
    payload: (toggle === true || toggle === false) ? toggle : null
  }
}
