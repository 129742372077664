import React, {useEffect, Suspense} from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import Navigation from './components/Navigation';
import Amenities from './components/Amenities';
import MainPage from './components/MainPage';
import Story from './components/Story';
import Suites from './components/Suites';
import Floorplans from './components/Floorplans';
import Neighbourhood from './components/Neighbourhood';
import Overview from './components/Overview';

// import AuthenticateUser from './features/authentication/authenticationActions';
import { loadProjectData } from './redux/actions/projectActions';
import { connect } from 'react-redux';

import './App.css';

function App(props) {

  var containerStyle = {
       padding: '24px',
      marginLeft: 240,
      transition: 'margin 700ms'
  };

  useEffect(() => {
    props.loadProjectData();
  }, []);

  const removeBackground = () => {
    document.documentElement.style.setProperty('--asset-background_image', props.project.background_image);
  }

  const addBackground = () => {
    document.documentElement.style.setProperty('--asset-background_image', 'none');
  }
  
  return (
      <div className="Background">
        <React.StrictMode>
          <Router>
          <Suspense fallback={<div> loading... </div>}>
            
            {/* {props.isLoaded ?  */}
            <Navigation floorplans={props}/>
            {console.log('updateNav!')}
            {console.log(props)}
          </Suspense>
            <div className="Container" style={containerStyle} tag="layout">

              <Routes>
                <Route path='/' element={<MainPage />} />
                <Route path='/overview' element={<Overview />} />
                <Route path='/amenities' element={<Amenities />} />
                <Route path='/neighbourhood' element={<Neighbourhood />} />
                <Route path='/story' element={<Story />} />
                <Route path='/suites' element={<Suites />} />
                <Route path='/floorplans' element={<Floorplans />} />
                <Route path='/floorplans/:id' element={<Floorplans />} />
                {/* <Navigate from="/floorplans/:id/reload" to="/floorplans/:id" /> */}
              </Routes>
              
            </div>
          </Router>
        </React.StrictMode>
      </div>
  );
}

const mapStateToProps = (state) => { 
  return { 
    project: state.project
    // auth: state.authentication
  }
}

export default connect(mapStateToProps, { loadProjectData })(App);
// export default App;
