import React, { useEffect, useState } from 'react';
// import Container from '@mui/material/Container';
import Masonry from '@mui/lab/Masonry';
// import { styled } from '@mui/material/styles';
// import Paper from '@mui/material/Paper';
import ListItemButton from '@mui/material/ListItemButton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Carousel, { Modal, ModalGateway } from "react-images";
import IconButton from '@mui/material/IconButton';
// import Skeleton from '@mui/material/Skeleton';
// import ImageItem from './ImageItem';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

import { loadOverviewData } from '../redux/actions/overviewActions';
import { connect } from 'react-redux';
import { withRouter } from "../redux/actions/withRouter";

// const Label = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(0.5),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
//   borderBottomLeftRadius: 0,
//   borderBottomRightRadius: 0,
// }));

const Overview = (props) => {

  const [currentImageIndex, setCurrentImageIndex] = useState(1);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setViewerIsOpen(true);
  }

  const closeLightbox = () => {
    setCurrentImageIndex(0);
    setViewerIsOpen(false);
  }

    useEffect(() => {
      props.loadOverviewData();
      document.documentElement.style.setProperty(`--asset-${'background_image'}`, 'none');
      document.title = props.title + ' - Overview';
    }, []);

    return (
      <div className="overview">
        {/* <div> */}
          {/* {!props.overview.isLoaded ? null :  */}
          {/* // <> */}
          <ImageList variant="masonry" cols={3} gap={8}>
            {props.overview.images.map((item, index) => (
                <ImageListItem key={index} onClick={(e)=> {openLightbox(index)}}>
                    <img
                      src={item.src}
                      // width={item.width}
                      // height={item.height}
                      alt={item.title}
                      // loading="lazy"
                    />
                  <ImageListItemBar
                        title={item.title}
                        // actionIcon={
                        //     <IconButton
                        //       sx={{ color: 'rgba(255, 255, 255, 1)' }}
                        //     >
                        //       <BookmarkBorderIcon />
                        //     </IconButton>
                        // }
                      />
                </ImageListItem>
            ))}
          </ImageList>

          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImageIndex}
                  views={props.overview.images.map(x => ({
                    ...x,
                    width: x.width,
                    height: x.height,
                    src: x.src,
                    caption: x.title
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
      </div>
    )
  }

  const mapStateToProps = (state) => { 
    return { 
      overview: state.overview,
    }
  }

  
  export default withRouter(connect(mapStateToProps, { loadOverviewData })(Overview));