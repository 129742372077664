import ACTION_TYPES from '../actionTypes.json';
import apiService from '../../services/apiService';
import assetBuilder from '../../services/assetBuilder';

export const LoadSuitesData = () => async(dispatch) => {
  const suitesData = await apiService.getSuitesData();
  console.log(suitesData);
  const images = suitesData.map(o => {
    return {
      id: o.id,
      alt: o.title,
      src: assetBuilder(o.asset),
      // isVideo: o.asset ? o.asset.endsWith('mp4') : false, 
      title: o.title,
      key: `${o.id}`,
      width: o.width / 3,
      height: o.height / 3,
    }
  });

  dispatch({
    type: ACTION_TYPES.SUITES_ACTIONS.FETCH_DATA,
    payload: {
      images,
      activeImage: 0,
      expandedView: false,
      // isLoaded: true
    }
  });
}

export const SuitesSetActiveImage = (id) => {
  return {
    type: ACTION_TYPES.SUITES_ACTIONS.SET_ACTIVE_IMAGE,
    payload: id
  }
}

export const SuitesToggleExpandedView = (toggle) => {
  return {
    type: ACTION_TYPES.SUITES_ACTIONS.TOGGLE_EXPANDED_VIEW,
    payload: (toggle === true || toggle === false) ? toggle : null
  }
}
