import ACTION_TYPES from '../actionTypes.json';

const defaultObject = {
    id: 1,
    logo: "",
    title: "",
    description: "",
    url: "",
    website_url: "",
    asset: "",
    isLoaded: false
}


const storyReducer = (current = { ...defaultObject }, { type, payload }) => {
  if (type === ACTION_TYPES.BUILDER_STORY)
    return { ...payload, isLoaded: true }
  return current;
}

export default storyReducer;