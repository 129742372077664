
// import {
//     useLocation,
//     useNavigate,
//     useParams
//   } from "react-router-dom";
  
//   export const withRouter = Component => {
//     return (props) => {
//       const  location = useLocation();
//       const  navigate = useNavigate();
//       const  params = useParams();

//       return (
//         <Component
//           {...props}
//           router={{ location, navigate, params }}
//         />
//       );
//     }
//     // return ComponentWithRouterProp(Component);
//   }

import { useNavigate  } from 'react-router-dom';

export const withRouter = (Component) => {

  const Wrapper = (props) => {
    const history = useNavigate();
    return (
      <Component
        navigate={history}
        {...props}
      />
    );
  };
  
  return Wrapper;
};