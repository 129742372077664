import React, { useEffect, useState, useCallback } from 'react';
import { Routes, Route, useParams } from "react-router-dom";

import Container from '@mui/material/Container';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Carousel, { Modal, ModalGateway } from "react-images";
import IconButton from '@mui/material/IconButton';
import { useLocation } from "react-router-dom";
import Gallery from "react-photo-gallery";

import { FloorplansFetch } from '../redux/actions/floorplanActions';
import { connect } from 'react-redux';
import { withRouter } from "../redux/actions/withRouter";

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { Category } from '@mui/icons-material';

const Floorplans = (props) => {

    const [currentImageIndex, setCurrentImageIndex] = useState(1);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [filter, setFilter] = useState('');
    const [filteredFloorplans, setFilteredFloorplans] = useState();
    const [gotFilter, setGotFilter] = useState(false);

    let params = useParams();
    
    document.title = props.title + ' - Floorplans';
    document.documentElement.style.setProperty('--asset-background_image', 'none');

    // const openLightbox = (index) => {
    //     setCurrentImageIndex(index);
    //     setViewerIsOpen(true);
    // }

    const openLightbox = (index) => {
        setCurrentImageIndex(index);
        setViewerIsOpen(true);
    }

    // const closeLightbox = () => {
    //     setCurrentImageIndex(0);
    //     setViewerIsOpen(false);
    // }

    // const openLightbox = useCallback((event, { photo, index }) => {
    //     setCurrentImageIndex(index);
    //     setViewerIsOpen(true);
    //   }, []);
    
    const closeLightbox = () => {
        setCurrentImageIndex(0);
        setViewerIsOpen(false);
    }

    const gotoPrevious = () => {
        currentImageIndex > 0 && setCurrentImageIndex(currentImageIndex - 1);
    }

    const gotoNext = () => {
        currentImageIndex + 1 < props.overview.images.length &&
        setCurrentImageIndex(currentImageIndex + 1);
    }

    useEffect(() => {
        console.log('fetching with filter: '+params.id);
        // console.log(params.id);
        setFilter(params.id);
        props.FloorplansFetch(params.id);
        // setCurrentImageIndex(0);
    }, [params.id]);

    return (

    <div className="floorplans">
        {console.log('---------------')}
        {/* {console.log(props)} */}
        {/* {CheckData()} */}
        
        <ImageList variant="masonry" cols={3} gap={8}>
        {/* {props.floorplans.images.map((item, index) => ( */}
        {/* {props.floorplans.images.filter(isCategory).map((item, index) => ( */}
        {props.floorplans.images.map((item, index) => (
        // {console.log(filteredFloorplans)}
        // {filteredFloorplans.map((item, index) => (
            // <div key={index}>
            // {({ ref, open }) => (
            // <ImageListItem key={index} onClick={() => openLightbox(index)}>
            <ImageListItem key={index} onClick={(e)=> {openLightbox(index)}}>
            {/* <ImageListItem key={index} onClick={(e)=> {openLightbox(index)}}> */}
                <img
                // ref={ref} 
                // onClick={open} 
                src={`${item.src}?fit=cover&width=${item.width}&height=${item.height}&quality=80`}
                // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
                />
                {/* {console.log(item)} */}
                <ImageListItemBar
                    title={item.title}
                    // actionIcon={
                    //     <IconButton
                    //       sx={{ color: 'rgba(255, 255, 255, 1)' }}
                    //       // aria-label={`info about ${item.title}`}
                    //     >
                    //       <BookmarkBorderIcon />
                    //     </IconButton>
                    // }
                />
            </ImageListItem>
            // ))}
        ))}
        </ImageList>

        {/* <Gallery photos={props.floorplans.images} onClick={openLightbox} /> */}
        <ModalGateway>
            {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
                <Carousel
                    currentIndex={currentImageIndex}
                    views={props.floorplans.images.map(x => ({
                        ...x,
                        src: x.src,
                        caption: x.title,
                        width: x.width,
                        height: x.height,
                    }))}
                />
            </Modal>
            ) : null}
        </ModalGateway>
      </div>
    );
}

const mapStateToProps = (state) => {
    return { floorplans: state.floorplans }
}

export default withRouter(connect(mapStateToProps, { FloorplansFetch })(Floorplans));