import history from './index';

// History service is created mostly for the 3D model
// Before we can safely jump pages we need to shut down the unity model
// This allows us to subscribe a callback to be executed before the page jumps
// Allowing us to quit the instance before the page jumps and the canvas is unloaded

class historyService {
  static _subscriptions = [];

  static push = async (target) => {
    for(const callback of this._subscriptions) {
      await callback();
    }
    history.push(target);
  }

  static subscibe = (callback) => {
    if (!this._subscriptions.includes(callback))
      this._subscriptions.push(callback);
    else 
      console.error('Attempted to subscribe callback which already existed.', callback)
  }

  static unsubscribe = (callback) => {
    if (this._subscriptions.includes) 
      this._subscriptions = this._subscriptions.filter(s => s !== callback);
    else 
      console.error('Could not find subsribed callback', callback);
  }
}

export default historyService;